<template>
  <div id="aiLoading" ref="aiLoading"></div>
</template>

<script>
import axios from 'axios'
import lottie from 'lottie-web'

export default {
  props: {},
  components: {},
  data () {
    return {
      lottieObj: {}
    }
  },
  methods: {
    async initLottie () {
      const element = this.$refs.aiLoading
      const { data } = await axios.get('https://img.infox-med.com/webImg/infoxmedV2/icons/aiLinkLoading.json')
      this.lottieObj = lottie.loadAnimation({
        container: element,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        name: 'translateIcon',
        animationData: data
      })
      this.lottieObj.setSpeed(1.5)
      this.lottieObj.play()
    }
  },
  mounted () {
    this.initLottie()
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
#aiLoading {
  width: 20px;
  height: 20px;
  margin-left: 30px;
}
</style>
