<template>
  <div class="mind_container">
    <div class="container" v-show="content">
      <svg id="markmap"></svg>
      <div class="seeLarge flc hand" @click="openLarge">
        全屏
      </div>
    </div>
    <AiLoading v-if="!content" />

    <MyDialog :isShow.sync="showLarge" width="100vw" height="100vh" :isBg="true" zIndex="3000"
              title="思维导图" bg="rgba(0, 0, 0, 0.1)">
      <div class="dialog_content">
        <svg id="markmapLarge"></svg>
      </div>
    </MyDialog>
  </div>

</template>

<script>
import { Transformer } from 'markmap-lib';
import { Markmap } from 'markmap-view';
import MyDialog from '@/components/myDialog/newIndex.vue'
import AiLoading from '@/components/aiSendMsg/components/aiLoading.vue'
export default {
  props: {
    content: String
  },
  components: {
    MyDialog,
    AiLoading
  },
  data () {
    return {
      showLarge: false
    }
  },
  methods: {
    openLarge () {
      this.showLarge = true
      setTimeout(() => {
        this.render('#markmapLarge', true)
      }, 300);

    },
    renderMarkmap () {
      this.render('#markmap')
    },
    render (id, clear = false) {
      this.$nextTick(() => {
        if (!this.content) return;
        const markmap = document.querySelector(id)
        if (clear) {
          markmap.innerHTML = ''
        }
        if (markmap.innerHTML) return
        const transformer = new Transformer();
        const { root } = transformer.transform(this.content);
        Markmap.create(id, null, root)
      })
    }
  },
  created () {

  },
}
</script>

<style lang="less" scoped>
.mind_container {
  width: 100%;
  height: 100%;
}
.container {
  position: relative;
  width: 100%;
  height: 100%;
}
#markmap {
  width: 100%;
  height: 100%;
}
.seeLarge {
  position: absolute;
  top: 0;
  right: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: #fff;
  font-weight: 600;
  background: linear-gradient(90deg, #03d7ff 0%, #7f25fc 100%);
}

#markmapLarge {
  width: 100vw;
  height: 100vh;
}
</style>
