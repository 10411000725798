<template>
  <div class="pdfAiChat">
    <aiChart fId="113" :beforSendData="beforSendData" :exampleInfo="exampleInfo"
             :aiConfig="exampleInfo" />
  </div>
</template>

<script>
import aiChart from '@/components//aiSendMsg/aiChat'

export default {
  props: {
    docInfo: Object
  },
  components: {
    aiChart
  },
  data () {
    return {
      exampleInfo: {
        title: '对话PDF',
        subTitle: '',
        tip: '我是这篇文献的PDF原文，你想了解PDF中的任何内容都可以问我。',
        icon: 'https://img.infox-med.com/webImg/infoxmedV2/icons/111.png',
        example: [
          {
            label: '整理文献核心内容',
            value: {
              content: '整理文献核心内容',
              attachment: this.docInfo.id
            }
          },
          {
            label: '文献中采取的实验方法和实验技术是什么？',
            value: {
              content: '文献中采取的实验方法和实验技术是什么？',
              attachment: this.docInfo.id
            }
          }
        ]
      }
    }
  },
  methods: {
    beforSendData (data) {
      data.attachment = this.docInfo.id
      return data
    }
  },
  created () {

  },
}
</script>

<style lang="less" scoped>
.pdfAiChat {
  width: 100%;
  height: 100%;
}
::v-deep .aiPageContainer {
  padding: 0 20px;
  .aiConttainer {
    padding-top: 10px;
  }
}

::v-deep .send_container {
  padding-bottom: 5px;
}
</style>
