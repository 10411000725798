<template>
  <div class="example_container">
    <div class="top">
      <img :src="config.icon" alt="" class="aiIcon">
      <div class="ai_info">
        <div class="ai_name">{{ config.title }}</div>
        <div class="ai_subtitle">{{ config.subTitle }}</div>
      </div>
    </div>
    <div class="tip" v-html="config.tip"></div>
    <div class="examples">
      <div class="examples_item" v-for="(item,i) in config.example" :key="i">
        <div class="example" :class="{ 'hand': !item.readonly }" @click="trigger(item)">
          <span class="item_label">{{ item.label }}</span>
          <el-icon class="el-icon-arrow-right" v-if="!item.readonly">
          </el-icon>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default: () => ({
        icon: '',
        title: '',
        subTitle: '',
        tip: '',
        example: []
      })
    }
  },
  components: {},
  data () {
    return {

    }
  },
  methods: {
    trigger (item) {
      if (item.readonly) return
      this.$emit('toExample', item)
    }
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.example_container {
  font-size: 14px;
  color: #000000;
}

.top {
  display: grid;
  grid-template-columns: 68px 1fr;
  align-items: center;
  column-gap: 16px;
  margin-bottom: 30px;

  .aiIcon {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    object-fit: cover;
  }

  .ai_name {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 6px;
  }

  .ai_subtitle {
    color: #666666;
  }
}

.tip {
  font-size: 16px;
  color: #000000;
  margin-bottom: 30px;
}

.examples {
  .examples_item {
    display: flex;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .item_label {
    display: inline-block;
    min-width: 200px;
  }

  .example {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 20px;
    align-items: center;
    background-color: #fff;
    padding: 10px 15px;
    border-radius: 8px;

    .el-icon {
      color: #979797;
    }
  }
}
</style>
