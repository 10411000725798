<template>
  <div class="aiPageContainer " ref="pageContentRef">
    <div class="aiConttainer">
      <div class="content">
        <div class="example" v-if="!msgList.length && exampleInfo.title">
          <AiExample :config="exampleInfo" @toExample="toExample" />
        </div>

        <div class="message_list">
          <div class="mes_item" v-for="item in msgList" :key="item.ts">
            <slot name="userMsg" :row="item" v-if="item.role === 'user'">
              <div class="user_msg">
                <div class="user_msg_content">{{ item.content }}</div>
              </div>
            </slot>

            <slot :row="item" v-if="item.role === 'ai'">
              <div class="ai_msg">
                <slot name="aiMsgTitle">
                  <div class="ai__msg_top">
                    <div class="role_info">
                      <img :src="aiConfig.icon" alt="" class="ai_icon">
                      <div class="ai_name">{{ aiConfig.title }}</div>
                    </div>
                    <slot name="aiMsgTip" :config="aiConfig" :row="item">

                    </slot>
                  </div>
                </slot>

                <div class="ai_reply_content" v-if="[1, 8].includes(item.messageType)">
                  <span v-html="mdToHtml(item.content)"></span>
                </div>
                <div v-if="item.messageType === 'error'">{{ item.content }}</div>
              </div>
            </slot>
          </div>

          <div class="link_loading mes_item" v-show="linkLoading">
            <slot name="aiMsgTitle">
              <div class="ai__msg_top">
                <div class="role_info">
                  <img :src="aiConfig.icon" alt="" class="ai_icon">
                  <div class="ai_name">{{ aiConfig.title }}</div>
                </div>
              </div>
            </slot>
            <AiLoading />
          </div>

        </div>
      </div>

      <AiSend ref="aiSendRef" class="sendBox" :fId="fId" :sId="sessionId" @send="sendMsg"
              @pushMsg="pushMsg" @pushFinish="pushFinish" @linkErr="linkErr" @setScroll="setScroll"
              @startSend="startSend" :beforSendData="beforSendData" @wsClose="wsClose" />
    </div>

  </div>
</template>

<script>
import AiSend from './components/send.vue'
import { marked } from '@/utils/marked.esm.js'
import AiLoading from './components/aiLoading.vue'
import AiExample from './components/aiExample.vue'

export default {
  props: {
    exampleInfo: {
      type: Object,
      default: () => ({})
    },
    beforSendData: Function,
    isScroll: {
      type: Boolean,
      default: true
    },
    aiConfig: {
      type: Object,
      default: () => ({})
    },
    fId: String
  },
  components: {
    AiLoading,
    AiSend,
    AiExample
  },
  data () {
    return {
      linkLoading: false,
      msgList: [],
      sessionId: null
    }
  },
  methods: {
    startSend () {
      this.linkLoading = true
    },
    setScroll (type) {
      const pageContentRef = this.$refs.pageContentRef
      if (!pageContentRef) return
      if (!this.isScroll && type === 'push') return
      pageContentRef.scrollTop = pageContentRef.scrollHeight
    },
    toExample (item) {
      this.$refs.aiSendRef.submitSend(item.value || item.label)
    },
    sendMsg (data) {
      this.msgList.push({
        ...data,
        role: 'user'
      })
    },
    pushFinish () {

    },
    pushMsg (data) {
      this.sessionId = data.sessionId
      this.linkLoading = false
      if (![1, 8].includes(data.messageType)) {
        this.msgList.push({
          content: '暂不支持此消息类型',
          messageType: 'error',
          role: 'ai'
        })
        return
      }
      const lastinfo = this.msgList.at(-1)
      if (lastinfo.role === 'user') {
        this.msgList.push({
          ...data,
          role: 'ai'
        })
      }
      if (lastinfo.role === 'ai') {
        lastinfo.content += data.content
      }
    },
    wsClose () {
      this.linkLoading = false
    },
    linkErr (error) {
      this.linkLoading = false
      if (error.reason === 'sessionId或token无效') {
        this.msgList.push({
          content: '登陆已过期，请在重新登录',
          role: 'ai'
        })
        return
      }
      if (error.reason) {
        const lastinfo = this.msgList.at(-1)
        if (lastinfo.role === 'user') {
          this.msgList.push({
            content: error.reason,
            role: 'ai'
          })
        }
        if (lastinfo.role === 'ai') {
          lastinfo.content = error.reason
        }
      }
    },
    mdToHtml (str) {
      if (!str) return ''
      const regex = /【.*?†.*?】/g
      return marked.parse(str.replace(regex, ''))
    }
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
.aiPageContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.aiConttainer {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 30px 0 0;
}
.content {
  flex: auto;
  flex-shrink: 0;
  overflow-y: auto;
}

.sendBox {
  position: sticky;
  bottom: 0;
  z-index: 30;
}

.message_list {
  .mes_item {
    font-size: 16px;
    color: #000000;
    line-height: 2em;
    margin-bottom: 20px;
  }

  .user_msg {
    display: flex;
    justify-content: flex-end;
  }

  .user_msg_content {
    background: linear-gradient(309deg, #0552d5 0%, #478bff 100%);
    border-radius: 14px 14px 4px 14px;
    padding: 7px 15px;
    color: #fff;
  }

  .ai_msg,
  .link_loading {
    .ai__msg_top {
      display: grid;
      grid-template-columns: repeat(2, auto);
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    .role_info {
      display: grid;
      grid-template-columns: repeat(2, auto);
      column-gap: 5px;
      align-items: center;
      justify-content: start;
      font-weight: 600;
      font-size: 14px;
      color: #000000;

      .ai_icon {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}

:deep(.ai_reply_content) {
  position: relative;

  ul,
  ol {
    padding-left: 40px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }

  li {
    list-style: initial;
  }

  .reply_loading {
    position: absolute;
    width: 3px;
    height: 20px;
    background-color: #000;
  }
}
</style>
