<template>
  <!-- 文献原文PDF格式展示组件 -->
  <div class="articlePDF ">
    <div class="pdf_view" :class="{ 'pdf_grid': isShowPdfZhView }">
      <div class="pdf_original_container">
        <!-- 功能栏 -->
        <div v-if="category !== '10' && pdfUrl" v-show="!isShowPdfZhView"
             class="options_box no_select" :class="{r20:isUnfoldAiOptions}">
          <TranslatePdf :pdfPageTotal="pdfPageTotal" :documentId="documentId" @showPdfZh="showPdfZh"
                        :pdfZhInfo="pdfZhInfo" />
          <!-- 下载按钮  -->
          <el-tooltip v-loading="isLoading" element-loading-spinner="el-icon-loading"
                      popper-class="tooltip" effect="light" content="会员可下载至本地" placement="bottom"
                      :visible-arrow="false" :enterable="false">
            <i @click="downloadPdf" class="el-icon-download button"></i>
          </el-tooltip>

          <!-- 笔记 -->
          <el-tooltip popper-class="tooltip" effect="light" content="全文笔记" placement="bottom"
                      :visible-arrow="false" :enterable="false">
            <i @click="goUserDownload"
               class="iconfont button jilu icon-a-yuedubijiiconqiepian1"></i>
          </el-tooltip>
          <!-- 纠错 -->
          <Correction :documentId="documentId" />
        </div>

        <!-- PDF阅读器 -->
        <iframe ref="iframe" noResize frameborder="0"
                :src="`./pdfjs/web/viewer.html?file=${this.pdfUrl}`" scrolling="no"
                class="pdf-window">
        </iframe>

        <!-- 悬浮图标菜单 -->
        <div v-if="isIcon" class="icon" :style="{ top: positionY, left: positionX }">
          <!-- 翻译 -->
          <el-tooltip content="段落翻译" placement="top-start" :enterable="false">
            <img @click.stop="translate" class=" hand" src="@/assets/img/pdf/translate.png">
          </el-tooltip>

          <!-- 复制 -->
          <el-tooltip content="段落复制" placement="top" :enterable="false">
            <img @click.stop="getcopy" v-clipboard:copy="messageTitle" v-clipboard:success="onCopy"
                 v-clipboard:error="onError" class=" hand" src="@/assets/img/pdf/fuzhi.png">
          </el-tooltip>

          <!-- 笔记 -->
          <el-tooltip content="段落笔记" placement="top-end" :enterable="false">
            <img @click="setNote" src="@/assets/img/pdf/biji.png" />
          </el-tooltip>
        </div>
        <!-- 翻译文本盒子 -->
        <div class="fanyi-box" v-show="drawer" @click="hideBox">
          <transition name="yi">
            <div class="text" v-if="drawer" @click.stop>
              <div class="title">
                <img src="@/assets/img/pdf/vipTitle.png">
                尊享翻译
              </div>
              <p class="original-text">{{ text }}</p>
              <i class="division"></i>
              <p>{{ translateTxt }}</p>
            </div>
          </transition>
        </div>

        <!-- 非会员弹窗 -->
        <VipTipDialog :show.sync="noVip" />

        <!-- 笔记弹窗 -->
        <transition name="note">
          <div class="note-item" v-if="noteBox" @click.stop>
            <div class="node-item-top">
              <h3>笔记</h3>
              <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 10 }" maxlength="100"
                        show-word-limit placeholder="#写下笔记#" v-model="textarea" @input="setText"
                        resize="none">
              </el-input>
              <div class="btn-box">
                <span @click="uploadNote" class=" hand">保存</span>
              </div>
              <!-- 标题 -->
              <div class="title-box">
                <span>段落</span>
              </div>
              <!-- 段落内容 -->
              <div class="paragraph-text">
                <span class="quanwen" v-if="text.length === 0">全文</span>
                <span v-else> {{ text }}</span>
              </div>
              <h4>历史笔记</h4>
            </div>

            <div class="lishi-bj" v-for="note in noteList" :key="note.id">
              <div class="top">
                <span class="top-time">{{ $initTime(note.createdTime, 'yyyy-MM-DD HH:mm') }}</span>
                <el-popconfirm confirm-button-type="text" placement="bottom-start" title="确定删除吗？"
                               @confirm="deleteNote(note.id)">
                  <span slot="reference" class=" hand top-btn">删除</span>
                </el-popconfirm>
              </div>
              <!-- 笔记标题 -->
              <div class="biji-text">
                <i class="iconfont text-icon icon-biji"></i>
                <span class="text-title">笔记</span>
                <i v-if="note.noteText.length > 54" @click="openBj(note)"
                   class="el-icon-caret-bottom  hand" :class="{ 'rotate-icon': note.bijiText }"></i>
              </div>
              <!-- 笔记内容 -->
              <div class="text-item" :class="{ 'open-text': note.bijiText }">{{ note.noteText }}
              </div>
              <!-- 段落标题 -->
              <div class="biji-text">
                <i class="iconfont dl-icon icon-duanla"></i>
                <span class="text-title">段落</span>
                <i v-if="note.originalText.length > 80" @click="openDl(note)"
                   class="el-icon-caret-bottom  hand" :class="{ 'rotate-icon': note.textItem }"></i>
              </div>
              <!-- 段落内容 -->
              <div v-if="note.originalText" class="text-item"
                   :class="{ 'open-text': note.textItem }">
                {{ note.originalText }}</div>
              <div v-else class="dl-kong">全文</div>
            </div>
          </div>
        </transition>

      </div>

      <!-- 翻译容器 -->
      <div class="pdfzh_container" v-if="isShowPdfZhView">
        <div class="translate_2">
          <div class="pdfzh_top_options" :class="{pr20:isUnfoldAiOptions}">
            <div class="switchLinkage">
              <el-tooltip size="mini" :content="`${isLinkage ? '关闭' : '打开'}原文和译文同步滚动`"
                          placement="top" active-color="#4b639f">
                <el-switch v-model="isLinkage" active-color="#4b639f" inactive-color="#ccc">
                </el-switch>
              </el-tooltip>
              滚动互联
            </div>

            <span class="hidePdfZh hand" @click="hidePdfZh">关闭译文</span>
            <span class="hidePdfZh hand " :class="{ 'downloadZhPdfBtn': downloading }"
                  v-if="isPdfZhLoadend" @click="downloadZhPdf">
              <span class="download_btn">{{ downloading ? '导出中' : '导出译文' }}</span>
              <div class="progressBg" v-if="downloading"
                   :style="{ width: (downloadProgress.cur / downloadProgress.total * 100) + '%' }">
              </div>
            </span>
          </div>

          <TrsnslatePdfView ref="TrsnslatePdfView" :scaleFactor="scaleFactor" :id="documentId"
                            :url="pdfUrl" :pdfViewScroll="pdfViewScroll"
                            :pdfPageContainerSize="pdfPageContainerSize" />
        </div>

      </div>
    </div>

    <div class="ai_options_container" :class="{'fold_ai_options':!isUnfoldAiOptions}">
      <AiOptions v-if="docInfo.id && $store.getters.userInfo.isHy"
                 @setAiOptionsState="setAiOptionsState" :docInfo="docInfo" />
    </div>

  </div>
</template>
<script>
import { downloadPdf, gainTran, getPdfUrl, getDocumentById } from '@/api/article.js'
import { submitDocFeedback } from '@/api/feedback'
import { uploadNote, getNotesByDocumentId, deleteNote } from '@/api/note.js'
import { mapGetters } from 'vuex'
import { getCurrentBrowser, getOs } from '@/utils/index.js'
import guide from '@/api/guideline.js'
import Correction from './components/correction.vue'
import TranslatePdf from './components/translatePdf.vue'
import VipTipDialog from '@c/vipTipDialog/index.vue'
import TrsnslatePdfView from './components/translatePdfView.vue'
import AiOptions from './components/aiOptions/index.vue'

export default {
  name: 'PdfDetails',
  components: {
    Correction,
    TranslatePdf,
    VipTipDialog,
    TrsnslatePdfView,
    AiOptions
  },
  data () {
    return {
      pdfUrl: '', // PDF文件路径
      documentId: '', // 当前文章的id
      fileName: '', // 下载PDF文件名字
      isIcon: false, // 功能按钮显示状态
      text: '', // 鼠标选中的文本
      translateTxt: '', // 翻译过的文本
      messageTitle: '', // 复制的文本
      positionX: '', // 鼠标X轴
      positionY: '', // 鼠标Y轴
      drawer: false, // 翻译内容盒子状态位
      noVip: false, // 不是会员提示盒子显示状态位
      isLoading: false, // loading状态位
      noteBox: false, // 笔记盒子状态位
      textarea: '', // 笔记输入框内容
      noteList: [], // 笔记列表
      category: '',
      docInfo: {},
      pdfPageTotal: 0,
      isShowPdfZhView: false,
      pdfZhInfo: {},
      scaleFactor: 1,
      pdfViewScroll: {},
      isLinkage: true,
      pdfPageContainerSize: {},
      downloading: false,
      isPdfZhLoadend: false,
      downloadProgress: {
        cur: 0,
        total: 0
      },
      isUnfoldAiOptions: true
    }
  },
  methods: {
    setAiOptionsState (type) {
      this.isUnfoldAiOptions = type
    },
    downloadZhPdf () {
      if (this.downloading) return
      this.downloading = true
      this.$refs.TrsnslatePdfView.downLoadPdf(this.docInfo.docTitle)
    },
    setSectionId (section) {
      const regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g
      return section.heading.replace(regex, '').replace(/\s/g, '_').toLowerCase()
    },
    switchShowAbstract () {
      this.pdfZhInfo.ishowOriginalAbstract = !this.pdfZhInfo.ishowOriginalAbstract
    },
    switchShowOriginal (item) {
      item.isShowOriginal = !item.isShowOriginal
    },
    hidePdfZh () {
      this.isShowPdfZhView = false
      this.isLinkage = true
      const contentWindow = this.$refs.iframe.contentWindow
      if (contentWindow) {
        contentWindow.setToolbarDisplay(true)
        contentWindow.removeViewerContainerScroll()
      }
    },
    showPdfZh (data) {
      this.pdfZhInfo = data
      this.isShowPdfZhView = true
      const contentWindow = this.$refs.iframe.contentWindow
      if (contentWindow) {
        contentWindow.setToolbarDisplay(false)
        contentWindow.setViewerContainerScroll()
        setTimeout(() => {
          this.$refs.iframe.contentWindow.setPdfScrollTop(0)
        }, 20)
      }
    },

    // 反馈原文错误
    async feedback () {
      try {
        await submitDocFeedback({
          documentId: this.documentId,
          documentPmid: this.docInfo.pmid,
          documentTitle: this.docInfo.docTitle,
          documentDoi: this.docInfo.docDoi,
          feedbackType: '原文纠错',
          feedbackContent: 'PDF文件损坏',
          deviceName: getCurrentBrowser(),
          systemVersion: 'PC',
          terminalType: getOs(),
          appVersion: 'PC'
        })
      } catch (error) {
        console.log(error, '--')
      }
    },
    // 删除笔记
    async deleteNote (noteId) {
      try {
        await deleteNote({ noteId })
        this.getNotes()
        this.$message.success('删除笔记成功成功')
      } catch (error) {
        this.$message.error('删除笔记失败')
      }
    },
    // 获取当前文献的笔记列表
    async getNotes () {
      try {
        const res = await getNotesByDocumentId({ documentId: this.documentId })
        this.noteList = res.data.map(item => {
          return {
            ...item,
            bijiText: false, // 笔记展开状态位
            textItem: false // 段落展开状态位
          }
        })
      } catch (error) {
        this.$message.error('获取笔记列表失败')
      }
    },
    // 新增笔记
    async uploadNote () {
      try {
        if (!this.textarea) return this.$message.warning('请输入笔记内容')
        await uploadNote({
          documentId: this.documentId,
          originalText: this.text,
          noteText: this.textarea,
          type: this.text ? 0 : 1
        })
        this.$myCount({
          name: 'PC-段落笔记',
          itemId: this.documentId
        })
        this.$message.success('添加笔记成功')
        this.textarea = '' // 制空笔记输入框
        this.noteBox = false // 关闭笔记弹框
        this.getNotes() // 获取笔记列表
      } catch (error) {
        this.$message.error('添加笔记失败')
      }
    },
    // 展开段落内容
    openDl (note) {
      note.textItem = !note.textItem
    },
    // 展开笔记内容
    openBj (note) {
      note.bijiText = !note.bijiText
    },
    // 笔记字数提醒
    setText () {
      if (this.textarea.length >= 100) {
        this.$notify({
          title: '系统提示',
          message: '字数已达最大限制',
          type: 'warning'
        })
      } else if (this.textarea.length === 90) {
        this.$notify({
          title: '系统提示',
          message: '最多还可输入10个字符',
          type: 'warning'
        })
      }
    },
    // 段落笔记入口
    setNote () {
      if (this.text.length >= 500) {
        this.$confirm('您选中的段落已超过500个字符是否为您切换为全文笔记?', '系统提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          // 切换全文笔记
          this.text = ''
          this.isIcon = false // 关闭图标菜单
          this.noteBox = true // 开启笔记弹框
        }).catch(() => {
        })
      } else {
        this.isIcon = false // 关闭图标菜单
        this.noteBox = true // 开启笔记弹框
      }
      this.getNotes() // 获取笔记列表
    },
    // 全文笔记入口
    goUserDownload () {
      if (!this.isIcon) {
        this.text = ''
        this.noteBox = true
        this.getNotes() // 获取笔记列表
      } else {
        // 图标菜单显示是段落笔记判断字数
        this.setNote()
      }
    },
    // 复制选中的文本信息
    getcopy () {
      this.messageTitle = this.text
    },
    // 复制成功
    onCopy () {
      this.$message.success('复制成功')
    },
    // 复制失败
    onError () {
      this.$message.error('复制失败')
    },
    // 关闭会员权益提醒
    hideVipBox () {
      this.noVip = false
    },
    // 暴漏出的关闭翻译弹框盒子
    hideBox () {
      this.drawer = false // 关闭翻译盒子弹窗
      this.noteBox = false // 关闭笔记弹框
      this.textarea = '' // 重置笔记输入框内容
    },
    // 翻译文本信息
    async translate () {
      this.isIcon = false
      if (this.userInfo.isHy) {
        this.$myCount({
          name: 'PC-段落翻译',
          itemId: this.documentId
        })
        try {
          const res = await gainTran({ text: this.text })
          this.translateTxt = res.data
          this.drawer = true
        } catch (error) {
          this.$message.error('翻译失败')
        }
      } else {
        this.noVip = true
      }
    },
    // iframe页面触发事件并传递参数过来
    changeNodeMsg (text, x, y) {
      if (text) {
        this.text = text.toString().length < 2000 ? text.toString() : text.toString().substring(0, 2000)
        this.positionX = (y - 70 < 30 ? x - 70 : x - 40) + 'px'
        this.positionY = (y - 70 < 30 ? 30 : y - 70) + 'px'
        this.isIcon = true
      } else {
        this.isIcon = false
      }
    },
    // 点击下载文件触发
    async downloadPdf () {
      try {
        this.isLoading = true
        const res = await downloadPdf({
          documentId: this.documentId,
          userId: this.userId,
          type: 1,
          channel: '1'
        })
        if (res.code === 403) {
          if (res.msg === '您没有访问该资源的权限') {
            this.noVip = true
          } else {
            this.$alert(res.msg, '系统提示', {
              type: 'warning',
              center: true,
              callback: () => {
              }
            })
          }
        } else if (res.code === 0) {
          // 下载pdf文件
          this.downloadWithBlob(res.data)
        }
      } catch (error) {
        this.$message.error('下载资源获取失败')
      } finally {
        this.isLoading = false
      }
    },
    // 下载文件方法
    downloadWithBlob (url) {
      this.$myCount({
        name: 'PC-原文下载',
        itemId: this.documentId
      })
      fetch(url).then(res => res.blob()).then(blob => {
        const a = document.createElement('a')
        const url = window.URL.createObjectURL(blob)
        const filename = this.fileName + '.pdf'
        a.href = url
        a.download = filename
        a.click()
        window.URL.revokeObjectURL(url)
      })
    },
    // 获取pdfurl地址
    async getPdfUrl () {
      try {
        const res = await getPdfUrl({
          channel: '1',
          documentId: this.documentId
        })
        if (res.code === 403) return (this.noVip = true)
        if (res.code !== 0) return this.$message.warning(res.msg)
        this.pdfUrl = res.data
      } catch (error) {
        this.$message.warning(error)
      }
    },
    async getDocInfo () {
      const { data } = await getDocumentById({ documentId: this.documentId })
      this.docInfo = data
      this.fileName = data.docTitle || 'file'
    },
    async getGuideInfo () {
      const { data } = await guide.guideDetails({
        guidelineId: this.documentId
      })
      this.pdfUrl = data.qiniuUrl
    },
    pageInit () {
      this.documentId = this.$route.query.id
      this.category = this.$route.query.category
      this.exposeMsg()
      if (this.category === '10') {
        return this.getGuideInfo()
      }
      this.getDocInfo()

      this.getPdfUrl()
    },
    exposeMsg () {
      // 暴漏出的获取鼠标选中文本方法
      window.myid = (text, x, y) => {
        if (this.category !== '10' && this.userId) this.changeNodeMsg(text, x, y)
      }
      // 暴漏出的关闭翻译盒子
      window.hide = () => {
        if (this.category !== '10' && this.userId) this.hideBox()
      }
      // 同步译文的缩放因子
      window.$setScale = (scale) => {
        this.scaleFactor = scale //* 1.2952380952380953
      }
      // 设置子级滚动
      window.$PdfWiewScrollChange = (scroll) => {
        if (!this.isLinkage) return
        this.pdfViewScroll = scroll
        const pdfViewComponent = this.$refs.TrsnslatePdfView
        if (pdfViewComponent) {
          pdfViewComponent.setPdfIframeScroll(scroll)
        }
      }

      window.addEventListener('message', (e) => {
        if (!this.isLinkage) return
        const message = e.data
        // console.log(message)
        if (message.key === 'setPdfScrollTop') {
          const contentWindow = this.$refs.iframe.contentWindow
          contentWindow.setPdfScrollTop(message.data)
        }
        if (message.key === 'setPdfPageContainerSize') {
          this.pdfPageContainerSize = message.data
        }

        if (message.key === 'pdfLoadend') {
          this.isPdfZhLoadend = true
        }
        if (message.key === 'downloadProgress') {
          this.downloadProgress = message.data
          if (message.data.cur === message.data.total) {
            this.$message.success('译文已下载至本地')
            this.downloading = false
            this.downloadProgress = this.$options.data().downloadProgress
          }
        }

      })
    }
  },
  computed: {
    ...mapGetters(['userId', 'userInfo'])
  },
  created () {
    this.pageInit()
    localStorage.removeItem('pdfInfo')
    window.$loadingError = () => {
      this.$message.warning('PDF文件损坏，已自动帮您反馈')
      this.feedback()
    }
    window.$pdfLoad = (pdfDocument) => {
      this.pdfPageTotal = pdfDocument.numPages
    }

  },
  mounted () {
    // 自动让内嵌html页面获取焦点 控制页面权限
    this.$refs.iframe.focus()
  }
}
</script>
<style lang="less" scoped>
.articlePDF {
  position: relative;
  color: #000;
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: start;
  // 翻译内容盒子动画
  .yi-enter,
  .yi-leave-to {
    opacity: 0;
    transform: translatey(400px);
  }

  /* 进入和离开的动画时间段 */
  .yi-enter-active,
  .yi-leave-active {
    transition: all 1s ease;
  }

  // 笔记动画
  .note-enter,
  .note-leave-to {
    opacity: 0;
    transform: translatey(400px);
  }

  /* 进入和离开的动画时间段 */
  .note-enter-active,
  .note-leave-active {
    transition: all 1s ease;
  }

  .pdf_view {
    width: 100%;
    height: 100%;
    position: relative;

    .options_box {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0px;
      right: 58px;
      height: 32px;

      .button {
        padding: 0 5px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        border-radius: 3px;
        font-size: 18px;
        margin-right: 3px;
        color: #000;
        background-color: #f9f9fa;

        &:hover {
          background-color: #dddedf;
        }
      }

      .jilu {
        color: #000;
        // font-weight: 400;
        cursor: default;
      }
    }
    .r20 {
      right: 20px;
    }
    /deep/ .el-icon-loading {
      color: #000;
    }

    /deep/ .el-loading-spinner {
      top: 60%;
    }
  }
  .pdf_original_container {
    width: 100%;
    height: 100%;
  }
  .pdf-window {
    width: 100%;
    height: 100%;
  }

  .icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 138px;
    height: 40px;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 0 4px 0px #666;

    &::after {
      position: absolute;
      content: '';
      left: 38px;
      bottom: -20px;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: #ccc transparent transparent transparent;
      border-width: 10px;
    }

    img {
      width: 35px;
      height: 35px;
      padding: 5px;
      background-color: #fff;

      &:hover {
        background-color: #e7edf3;
        border-radius: 5px;
      }
    }
  }

  // 翻译弹框
  .fanyi-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color: rgba(62, 61, 61, 0.664);

    .text {
      position: absolute;
      width: 440px;
      min-height: 400px;
      max-height: 85vh;
      top: 10vh;
      right: 10px;
      padding: 30px 20px 50px;
      box-shadow: 0 0 10px 0 rgb(165, 165, 165);
      border-radius: 15px;
      background-color: #fff;
      overflow-x: hidden;
      overflow-y: auto;
      word-wrap: break-word;

      .title {
        width: 100%;
        padding: 5px 5px 0;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        color: #666666;
        margin-bottom: 15px;

        img {
          width: 34px;
          height: 14px;
        }
      }

      p {
        line-height: 24px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;

        &::before {
          display: inline-block;
          position: relative;
          top: 3px;
          content: '';
          width: 4px;
          height: 20px;
          border-radius: 5px;
          margin-right: 8px;
          background-color: #fcd7a1;
        }
      }

      // 原文样式
      .original-text {
        color: #666666;
        font-size: 18px;

        &::before {
          display: inline-block;
          position: relative;
          top: 3px;
          content: '';
          width: 4px;
          height: 14px;
          border-radius: 5px;
          margin-right: 5px;
          background-color: #fcd7a1;
        }
      }

      // 分割线
      .division {
        display: block;
        width: 100%;
        margin: 10px 0;
        border-bottom: 2px dotted #ccc;
      }
    }
  }

  //笔记弹框
  .note-item {
    position: absolute;
    width: 400px;
    min-height: 85vh;
    max-height: 85vh;
    top: 10vh;
    right: 10px;
    padding: 30px 0px 50px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgb(165, 165, 165);
    background-color: #fff;
    scrollbar-width: none;
    /* firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      // display: none;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      /* 滚动条手柄 */
      background-color: #00adb5;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      /*滚动条的轨道*/
      border-radius: 4px;
    }

    .node-item-top {
      padding: 0 20px;
    }

    h3 {
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      color: #666666;
      margin-bottom: 15px;
    }

    .el-textarea {
      font-size: 16px !important;
    }

    .btn-box {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      padding-right: 10px;
      font-size: 14px;
      color: #4b639f;
    }

    .title-box {
      margin-bottom: 10px;
      font-size: 16px;
    }

    .paragraph-text {
      width: 100%;
      height: 70px;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      color: rgb(74, 73, 73);

      .quanwen {
        display: block;
        padding-top: 30px;
        text-align: center;
        font-size: 18px;
      }
    }

    h4 {
      padding: 10px 0 0;
      font-size: 16px;
      text-align: center;
      color: #4b639f;
      border-top: 5px solid rgba(204, 204, 204, 0.5);
    }

    .lishi-bj {
      padding: 0 20px 10px;
      border-bottom: 2px solid #ccc;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0px;

        .top-time {
          font-size: 14px;
          color: #666;
        }

        .top-btn {
          font-size: 14px;
          color: #4b639f;
        }
      }

      // 笔记和段落样式
      .biji-text {
        position: relative;
        width: 100%;
        font-size: 16px;

        .text-title {
          margin-right: 5px;
          font-weight: 700;
          color: #000;
        }

        .text-icon {
          margin-right: 5px;
          font-size: 18px;
          font-weight: 500;
          color: #4b639f;
        }

        .dl-icon {
          margin: 0 3px 0 2px;
          font-size: 18px;
          color: #4b639f;
        }

        .el-icon-caret-bottom {
          position: absolute;
          top: 0px;
          right: 20px;
          font-size: 20px;
          color: #4b639f;
        }

        .rotate-icon {
          transform: rotate(180deg);
          transition: all 0.5s;
        }
      }

      // 笔记内容默认展示样式
      .text-item {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 14px;
        margin-bottom: 10px;
        color: #666;
      }

      // 段落全文样式
      .dl-kong {
        height: 32px;
        line-height: 32px;
        font-size: 16px;
        text-align: center;
      }

      // 笔记内容展开样式
      .open-text {
        display: block;
        overflow: visible;
      }
    }
  }
}

.pdf_grid {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  align-items: start;
}

.pdfzh_container {
  // padding-top: 40px;
  height: 100%;
  // overflow-y: auto;
  background-color: rgba(237, 237, 240, 1);
}

.pdfzh_top_options {
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  justify-content: end;
  column-gap: 10px;
  padding-right: 40px;
  height: 33px;
  border-bottom: 1px solid rgba(221, 222, 223, 1);
  background-color: rgba(249, 249, 250, 1);

  .switchLinkage {
    padding-right: 10px;
    border-right: 1px solid rgba(0, 0, 0, 0.3);
  }

  .hidePdfZh {
    padding: 0 5px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    border-radius: 3px;
    color: #000;
    .download_btn {
      position: relative;
      z-index: 90;
    }
    &:hover {
      background-color: #dddedf;
    }
  }

  .downloadZhPdfBtn {
    position: relative;
    color: #1a5f36;
    background-color: rgba(76, 158, 109, 0.2);
    &:hover {
      background-color: rgba(76, 158, 109, 0.2);
    }
  }
  .progressBg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: rgba(76, 158, 109, 0.8);
    opacity: 0.5;
  }
}
.pr20 {
  padding-right: 20px;
}
.ai_options_container {
  width: auto;
  height: 100%;
  background: #f5f7fb;
  overflow: hidden;
  transition: all 1s;
}
.fold_ai_options {
  width: 0;
}
</style>
